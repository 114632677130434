<template>
    <div class="main-background filter-page-container" :style="{ height: pageHeight + 'px' }">
        <div class="filter-page-back-button" @click="returnToCatalog">
            <chevron-left-icon class="back-button-icon" color="#4D4C4C" />
            <p class="back-button-text">Повернутися у каталог</p>
        </div>
        <div class="success-info-block">
            <check-icon class="check-icon" />
            <p class="success-info-text"> Ваше замовлення успішно оформлено і вже прямує до Вас!
                Найближчим часом з Вами зв'яжеться наш представник</p>
        </div>
        <div class="bottom-filter-button-container">
            <button class="back-to-catalog-button" @click="returnToCatalog">
                <div class="product-info-icon-container">
                    <search-custom-cart-icon />
                </div>
                Повернутися в каталог
            </button>
            <button class="back-to-bot-button" @click="setReturnToChatBot">
                Назад в бот
            </button>
        </div>
    </div>
</template>

<script>

import { ChevronLeftIcon, CheckIcon } from 'vue-feather-icons'
import SearchCustomCartIcon from "../icons/SearchCustomCartIcon.vue"

export default {
    name: "is-success-page",
    components: {
        ChevronLeftIcon,
        CheckIcon,
        SearchCustomCartIcon
    },
    props: {

    },
    data() {
        return {
            productCounter: 0,
            customerName: '',
            customerSecondName: '',
            customerPostNumber: '',
            onOrderNumber: '',
            customerCity: '',
            isWrongFormat: false,
            pageHeight: 0
        }
    },
    async mounted() {
        window.scrollTo(0, 0);
        this.pageHeight = window.innerHeight - 50
    },
    computed: {
         getOrderId() {
            return this.$store.getters.getOrderId
        },
        getSuccedOrder() {
            return this.$store.getters.getSuccedOrder
        }
    },
    methods: {
       async setReturnToChatBot() {
         window.Telegram.WebApp.close()
        },
        returnToCatalog() {
            this.$emit('setIsChangePage', 'product')
        },
    },
    watch: {

    }
}
</script>

<style scoped>

.main-background {
    background: #F3F3F5;
}
.filter-page-back-button {
    position: fixed;
    width: 100%;
    top: 0px;
    border-bottom: 1px solid #F0F0F0;
    background: #F3F3F5;
    padding: 27px 16px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    z-index: 10;
}

.back-button-icon {
    position: absolute;
}

.back-button-text {
    color: #4D4C4C;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    text-align: center;
}

.filter-page-container {
    display: flex;
    flex-direction: column;
}

.bottom-filter-button-container {
    background: #FFF;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.04);
  padding: 16px;
  margin-top: auto;
  position: fixed;
    width: 100%;
    bottom: 0px;
}

.product-info-icon-container {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 40px;
    height: 40px;
    background: #FFF;
    border-radius: 8px;
}

.product-info-icon-container svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.back-to-catalog-button {
  position: relative;
    border-radius: 8px;
    border: none;
    background: #EE1D23;
    color: #fff;
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
}

.back-to-bot-button {
  width: 100%;
  padding: 12px;  
  border-radius: 8px;
border: 1px solid #EE1D23;
background: transparent;
color: #EE1D23;
font-family: Helvetica;
font-size: 18px;
font-weight: 700;
line-height: 24px;
cursor: pointer;
margin-top: 10px;
}


.return-to-shop {
    padding: 12px;
    width: 100%;
    border-radius: 8px;
    background: #FFF;  
    color: #16ABB8;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    border: none;
    cursor: pointer;
    margin-bottom: 10px; 
    border: 1px solid #16ABB8;
}

.success-info-block {
    border-radius: 12px;
    border: 1px solid #D2D6DB;
    background: #FFF;
    margin: 80px auto 0 auto;
    width: 345px;
    padding: 12px 12px 26px 12px;
    display: flex;
    flex-direction: column;
}

.check-icon {
    width: 50px;
    height: 50px;
    color: #EE1D23;
    margin: 0 auto;
}

.success-info-text {
    color: #333;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    max-width: 300px;
    margin: 0 auto;
    max-width: 255px;
}

</style>