<template>
    <div class="main-background">
         <div class="filter-page-back-button" @click="returnToLastPage">
            <chevron-left-icon color="#4D4C4C"/>
            <p class="back-button-text">Детальна інформація</p>
             <div class="skip_btn">
                <button class="to-cart-button" @click="goToCartPage">
                  <div v-if="cartElements.length" class="cart-counter">{{ cartElements.length }}</div>
                  <shopping-cart-icon size="24" color="#4D4C4C"></shopping-cart-icon> 
                </button>
                </div>
          </div>
          <div class="main-image-container">
            <img :src=" openedProduct.img" alt="" class="main-image">
            <div class="product-main-info-container">
                <p class="product-info-name">{{ openedProduct.name }}</p>
                    <p class="product-price">{{ `${openedProduct.price} балів` }}</p>
            </div>
           
          </div>
          <div class="product-info-container">
            <p class="product-info-title">Опис</p>
            <p class="product-info">{{ openedProduct.description }}</p>
          </div>
          <div class="bottom-filter-button-container">
                <p class="bottom-container-balance-counter">{{`Мій баланс:${customerInfo.bonuses || 0} балів`}}</p>
                <button 
                class="filtration-button" 
                @click="setAddproductToCart"
                >
                <div class="product-info-icon-container">
                    <product-info-icon/>
                </div>
                {{ isAddedTocart ? 'Додано в корзину' : 'Додати в корзину'  }}
            </button>
          </div>
    </div>
</template>

<script>

import { ChevronLeftIcon} from 'vue-feather-icons'
import { ShoppingCartIcon } from 'vue-feather-icons'
import { mapMutations } from "vuex"
import ProductInfoIcon from "../icons/ProductInfoIcon.vue"

export default {
    name: "product-page",
    components: {
        ChevronLeftIcon,
        ShoppingCartIcon,
        ProductInfoIcon
    },
    props: {
        perPage: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            mainOpenProduct: {
                id: null,
                img: null,
                name: null,
                price: null
            },
            productCounter: 1,
            isAddedTocart: false
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        if (this.getCustomerCart.find(el => el.product_id.id === this.openedProduct.id)) {
            this.isAddedTocart = true
        }
    },
    computed: {
       openedProduct() {
         return this.$store.getters.openedProduct
       },
       perPageName() {
         return this.$store.getters.perPageName
       },
       getOrderId() {
        return this.$store.getters.getOrderId
       },
       getCustomerCart() {
        return this.$store.getters.getCustomerCart
       },
        cartElements() {
            return this.$store.getters.getCustomerCart;
        },
        customerInfo() {
            return this.$store.getters.customerInfo
        }

    },
    methods: {
         ...mapMutations(['addPerPage']),
        validateInput() {
            this.productCounter = this.productCounter.replace(/\D/g, '');

             const numericValue = parseInt(this.productCounter);
            if (numericValue > this.openedProduct.qty) {
                this.productCounter = this.openedProduct.qty.toString();
            } 
        },
        setChangeCounter(value) {
            if (value === '+') {
                this.productCounter = Number(this.productCounter) + 1
            } else {
                this.productCounter = Number(this.productCounter) - 1
            }
        },
        goToCartPage() {
            this.addPerPage('productInfo')
            this.$emit('setIsChangePage', 'cart')
        },
        setAddproductToCart() {
            if (this.isAddedTocart) {
                 this.$emit('setIsChangePage', 'cart')
            }
            let data = {
                customer_id: this.customerInfo.id,  
                product_id: this.openedProduct.id,
            }
            try {
              this.$store.dispatch('addProductToCart', JSON.stringify(data)).then(() => {
                this.isAddedTocart = true
                    this.$emit('setIsChangePage', 'cart')
              })
            } catch (error) {
                console.log(error.message)
            } 
        },
        returnToLastPage() {
            if (this.perPageName) {
              this.$emit('setIsChangePage', this.perPageName)  
            } else {
              this.$emit('setIsChangePage', 'product')
            }
        }
    },
    watch: {

    }
}
</script>

<style scoped>

.main-background {
    background: #F3F3F5;
}
.filter-page-back-button {
    position: fixed;
    width: 100%;
    top: 0px;
    background: #F3F3F5;
    border-bottom: 1px solid #F0F0F0;
    padding: 27px 16px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.product-main-info-container {
    padding: 16px;
}

.back-button-icon {
    position: absolute;
}

.back-button-text {
  color: #4D4C4C;
  text-align: center;
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.main-image-container {
   width: 100%;
   border-bottom: 1px solid #F0F0F0;
   padding-top: 70px;
}

.main-image {
    width: 100%;
}

.bottom-filter-button-container {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: #FFF;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.04);
  padding: 16px;
}

.bottom-container-balance-counter {
    text-align: center;
    color: #707070;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 12px;
}

.filtration-button {
    position: relative;
    border-radius: 8px;
    border: none;
    background: #EE1D23;
    color: #fff;
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
}

.product-info-icon-container {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 40px;
    height: 40px;
    background: #FFF;
    border-radius: 8px;
}

.product-info-icon-container svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.product-info-name {
   color: #000;
    font-family: Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.product-price-container {
    display: flex;
    gap: 6px;
}

.product-price {
    color: #EE1D23;
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

.product-info-container {
    padding: 0 16px 120px 16px;
}
.product-info-title {
   color: #000;
font-family: Helvetica;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}
.product-info {
  color: #707070;
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    margin-top: 12px;
}
.add-cart-bbutton {
    vertical-align: bottom;
}

.to-cart-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
}

.cart-counter {
  position: absolute;
  height: 14px;
  min-width: 14px;
  background: #C8392B;
  border-radius: 50%;
  padding: 2px;
  color: #FFF;
  font-family: 'Lato', sans-serif;
  font-size: 8px;
  font-weight: 700;
  line-height: 8px;
  top: -1px;
  right: -4px;
}

</style>